import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.css"
import tooth from "../images/tooth-icon-white@2x.png"
import { graphql } from "gatsby"
import Img from "gatsby-image"

const TreatmentSolutions = ({ data }) => (
  <Layout>
    <SEO title="Treatment Solutions" />
    <div className="container">
      <div className="banner">
        <h1>Treatment Solutions</h1>
      </div>
      <div className="featured-block-left">
        <div className="featured-content" id="preventative-solutions">
          <h2 className="headline">Preventative Solutions</h2>
          <em>
            Preventative solutions help our patients maintain a healthy smile.
          </em>
          <ul className="treatment-solutions-list">
            <li>Dental Cleanings</li>
            <li>Fluoride Treatments</li>
            <li>Sealants</li>
            <li>Periodontal Treatment</li>
          </ul>
        </div>
        <div className="featured-image">
          <Img
            fluid={data.brushingImage.childImageSharp.fluid}
            alt="preventative-solutions"
          />
        </div>
      </div>
      <div className="featured-block-right">
        {" "}
        <div className="featured-content" id="general-solutions">
          <h2 className="headline">General Dental Solutions</h2>
          <em>
            When problems arise, these are the solutions that get our patients
            smiling again
          </em>
          <ul className="treatment-solutions-list">
            <li>Composite (Tooth Colored) Fillings</li>
            <li>Root Canal Treatments</li>
            <li>Extractions</li>
            <li>TMJ Treatment</li>
          </ul>
        </div>{" "}
        <div className="featured-image">
          <Img
            fluid={data.generalSolutions.childImageSharp.fluid}
            alt="general-solutions"
          />
        </div>
      </div>
      <div className="featured-block-left">
        {" "}
        <div className="featured-content" id="fixed-solutions">
          <h2 className="headline">Fixed Solutions</h2>
          <em>
            Fixed solutions are most like natural teeth and include everything
            from crowns to implant bridges.
          </em>
          <ul className="treatment-solutions-list">
            <li>Crowns</li>
            <li>Bridges</li>
            <li>Veneers</li>
            <li>Implant crowns</li>
            <li>Implant bridges</li>
            <li>Fixed hybrid implant bridges</li>
          </ul>
        </div>
        <div className="featured-image">
          <Img
            fluid={data.removableSolutions.childImageSharp.fluid}
            alt="fixed-solutions"
          />
        </div>
      </div>
      <div className="featured-block-right">
        {" "}
        <div className="featured-content" id="removable-solutions">
          <h2 className="headline">Removable Solutions</h2>
          <em>
            This includes complete and partial dentures with or without dental
            implants.
          </em>
          <ul className="treatment-solutions-list">
            <li>Complete dentures</li>
            <li>Partial dentures</li>
            <li>Implant supported dentures</li>
          </ul>
        </div>{" "}
        <div className="featured-image">
          <Img
            fluid={data.removableSolutions.childImageSharp.fluid}
            alt="removable-solutions"
          />
        </div>
      </div>
      <div className="featured-block-left">
        {" "}
        <div className="featured-content">
          <h2 className="headline">Other Dental Solutions</h2>
          <em>To protect and enhance your smile.</em>
          <ul className="treatment-solutions-list">
            <li>Tooth Whitening</li>
            <li>Night Guards</li>
            <li>Sleep Apnea Appliances</li>
          </ul>
        </div>
        <div className="featured-image">
          <Img
            fluid={data.nightguard.childImageSharp.fluid}
            alt="other-solutions"
          />
        </div>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    brushingImage: file(relativePath: { eq: "toothbrush-variation.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    generalSolutions: file(relativePath: { eq: "general.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    removableSolutions: file(relativePath: { eq: "removable-solutions.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nightguard: file(relativePath: { eq: "nightguard.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }

`

export default TreatmentSolutions
